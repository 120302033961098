<!--
* 创建人：邓国伦
* 日 期：
* 描 述：公寓楼管理
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">公寓区域管理</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div>
        <el-select
          v-model="GYQBH"
          clearable
          placeholder="===公寓区域==="
          @change="getDataPageList"
        >
          <el-option
            v-for="(item, index) in listgyq"
            :key="index"
            :label="item.gyqmc"
            :value="item.gyqbh"
          ></el-option>
        </el-select>
        <el-input
          class="ml5"
          v-model="keyword"
          placeholder="公寓楼编号/名称"
          size="small"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="getDataPageList"
          >搜索</el-button
        >
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_add')"
          icon="el-icon-plus"
          @click="showAdd"
          >新增</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_edit')"
          icon="el-icon-edit"
          @click="showEdit"
          >编辑</el-button
        >
        <el-button
          size="small"
          v-if="hasPerms('sy_delete')"
          icon="el-icon-delete"
          @click="del"
          >删除</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="gylbh" label="编号" width="80">
        </el-table-column>
        <el-table-column prop="gylmc" label="名称" width="200">
        </el-table-column>
        <el-table-column prop="gyqmc" label="公寓区" width="200">
        </el-table-column>
        <el-table-column prop="lcs" label="楼层数" width="80">
        </el-table-column>
        <el-table-column prop="fjs" label="房间数" width="100">
        </el-table-column>
        <el-table-column prop="cws" label="床位数" width="100">
        </el-table-column>
        <el-table-column prop="manrs" label="男生人数" width="100">
        </el-table-column>
        <el-table-column prop="womanrs" label="女生人数" width="100">
        </el-table-column>
        <el-table-column prop="zrs" label="总人数" width="100">
        </el-table-column>
        <el-table-column label="入住率" width="100">
          <template slot-scope="scope">
            {{ ((scope.row.zrs / scope.row.cws) * 100).toFixed(1) }}%
          </template>
        </el-table-column>
        <el-table-column prop="fzr" label="负责人"> </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <!------------------添加 修改 -------------->
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form
        :model="info"
        v-if="dialogFormVisible"
        ref="formRef"
        :rules="infoRules"
      >
        <el-form-item label="公寓区域" label-width="120px" prop="GYQBH">
          <el-select v-model="info.GYQBH" style="display: block">
            <el-option
              v-for="(item, index) in listgyq"
              :key="index"
              :label="item.gyqmc"
              :value="item.gyqbh"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公寓楼编号" label-width="120px" prop="GYLBH">
          <el-input
            v-model="info.GYLBH"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="公寓楼名称" label-width="120px" prop="GYLMC">
          <el-input
            v-model="info.GYLMC"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item label="楼层数" label-width="120px" prop="PX">
          <el-input-number
            v-model="info.LCS"
            controls-position="right"
            :min="1"
            :max="50"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="负责人" label-width="120px" prop="FZR">
          <el-input
            v-model="info.FZR"
            autocomplete="off"
            maxlength="20"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import sg from '../../api/gygl'
import { getAuthorizeButtonColumnList } from '../../api/system'
export default {
  data() {
    return {
      active: 0,
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      dialogFormVisible: false, // 是否显示弹出框
      info: {
        ID: '',
        GYLBH: '',
        GYLMC: '',
        LCS: 1,
        GYQBH: '',
        LXDH: '',
        FZR: '',
      },
      infoRules: {
        GYLBH: [
          {
            required: true,
            trigger: 'blur',
            validator: (r, v, callback) => {
              if (v === '' || v === null) {
                return callback(new Error('请输入公寓楼名称'))
              } else {
                sg.exgylbh({ id: this.keyValue, bh: v })
                  .then((res) => {
                    if (res.code === 200) {
                      if (res.data.flag === true) {
                        return callback(new Error('编号重复'))
                      } else {
                        callback()
                      }
                    } else {
                      return callback(new Error('编号验证失败'))
                    }
                  })
                  .catch((e) => {
                    return callback(new Error('编号验证失败'))
                  })
              }
            },
          },
        ],
        GYLMC: [
          { required: true, message: '请输入公寓楼名称', trigger: 'blur' },
        ],
        GYQBH: [{ required: true, message: '请选择公寓区', trigger: 'blur' }],
      },
      listgyq: [],
      GYQBH: '', // 公寓区编号 用于查询
    }
  },
  components: {},
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    this.getDataPageList()
    sg.getgyqlist().then((res) => {
      if (res.code === 200) {
        this.listgyq = res.data
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      sg.getgylpagelist({
        queryJson: JSON.stringify({
          keyword: this.keywordl,
          GYQBH: this.GYQBH,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1
      this.keyValue = ''
      this.dialogFormVisible = true
    },
    // 显示编辑弹出框
    showEdit() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        sg.getgylformdata({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            Object.keys(res.data).forEach((k) => {
              this.info[k.toUpperCase()] = res.data[k]
            })
            this.info.ID = res.data.id
            this.info.GYQBH = res.data.gyqbh
            this.info.GYLMC = res.data.gylmc
            this.info.FZR = res.data.fzr
            this.info.LCS = res.data.lcs
            this.info.GYLBH = res.data.gylbh
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.info.ID = ''
      } else {
        this.info.ID = this.keyValue
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          sg.savegyl(this.info).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.getDataPageList()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 删除班级
    del() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.$confirm('是否确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            sg.del({ id: this.keyValue }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          })
          .catch(() => {})
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.info = JSON.parse(JSON.stringify(this.info))
      })
      this.$refs.formRef.resetFields()
    },
  },
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
</style>
