import { render, staticRenderFns } from "./gyl.vue?vue&type=template&id=f777dafa&scoped=true&"
import script from "./gyl.vue?vue&type=script&lang=js&"
export * from "./gyl.vue?vue&type=script&lang=js&"
import style0 from "./gyl.vue?vue&type=style&index=0&id=f777dafa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f777dafa",
  null
  
)

export default component.exports